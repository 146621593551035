import styles from '../styles/Admin.module.sass'
import React, { useState, useEffect } from 'react'
import { TextField, MenuItem, CircularProgress, Backdrop, Autocomplete } from '@mui/material'
import { Button, Input } from '../components'
import useAxios from '../hooks/useAxios'
import Table from '../components/Table/Table'

const Admin = () => {
    const axios = useAxios()
    const [companies, setCompanies] = useState([])
    const [users, setUsers] = useState([])
    const [, setIsLoading] = useState(true)
    const [modal, setModal] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [userData, setUserData] = useState({
        username: '',
        firstname: '',
        lastname: '',
        password: '',
        companies: [],
        permissions: {
            admin: false,
            qr: false,
            shipment: false,
            warehouse: false,
            sales: false,
            mobile: false
        },
        avatar: null
    })
    const [selectedImg, setSelectedImg] = useState('url(/img/logo.jpg)')
    const [companyData, setCompanyData] = useState({
        title: '',
        location: '',
        ebayKey: '',
        qrType: '',
        logo: null,
        APP_ID: '',
        CERT_ID: '',
        DEV_ID: '',
        RU_NAME: ''
    })


    const getCompanies = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get('/companies?all=true')
            setCompanies(res.data.data)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }
    const getUsers = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get('/users?all=true')
            setUsers(res.data.data)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCompanies()
        getUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleCompanySubmit = async e => {
        e.preventDefault()
        let data = companyData
        let id = null
        if(data._id) {
            id = data._id
            delete data._id
        }
        try {
            setIsSubmitting(true)
            const Data = new FormData()
            Data.append('title', data.title)
            Data.append('location', data.location)
            Data.append('qrType', data.qrType)
            Data.append('ebayKey', data.ebayKey)
            Data.append('APP_ID', data.APP_ID)
            Data.append('DEV_ID', data.DEV_ID)
            Data.append('CERT_ID', data.CERT_ID)
            Data.append('RU_NAME', data.RU_NAME)
            Data.append('logo', data.logo)
            console.log(Data)
            if(id) {
                const res = await axios.put(`/companies/${id}`, Data)
                if(res.status === 200) {
                    await getCompanies()
                    setModal(null)
                }
            } else {
                const res = await axios.post('/companies', Data)
                if(res.status === 200) {
                    await getCompanies()
                    setModal(null)
                }
            }
            setIsSubmitting(false)
        } catch(err) {
            console.log(err)
            setIsSubmitting(false)
        }
    }

    const handleCompanyData = e => {
        const { name, value } = e.target
        setCompanyData(prev => ({...prev, [name]: value}))
    }


    const handleImgUpload = e => {
        const file = e.target.files[0]
        const reader = new FileReader()
        // const url = reader.readAsDataURL(file)
        reader.onloadend = function () {
            setSelectedImg(`url(${reader.result})`)
        }
        setCompanyData(prev => ({...prev, logo: file}))
    }

    const handleUserData = e => {
        const { name, value, checked } = e.target
        if(name.split('.')[0] === 'permissions') setUserData(prev => ({...prev, permissions: {...prev.permissions, [name.split('.')[1]]: checked}}))
        else setUserData(prev => ({...prev, [name]: value}))
    }

    const handleUserSubmit = async e => {
        e.preventDefault()
        let data = userData
        data.companies = data.companies.map(item => item._id)
        let id = null
        if(data._id) {
            id = data._id
            delete data._id
            delete data.updatedAt
            delete data.createdAt
            delete data.avatar
            delete data.status
            delete data.admin
        }
        if(data.password === 'ENCRYPTED') delete data.password
        try {
            setIsSubmitting(true)
            if(id) {
                console.log(data)
                const res = await axios.put(`/users/${id}`, { data })
                if(res.status === 200) {
                    getUsers()
                    setModal(null)
                }
            } else {
                const res = await axios.post('/users', data)
                if(res.status === 200) {
                    getUsers()
                    setModal(null)
                }
            }
            setIsSubmitting(false)
        } catch(err) {
            console.log(err)
            setIsSubmitting(false)
        }
    }

    const [authUrl, setAuthUrl] = useState({
        url: '',
        accept: '',
        decline: ''
    })


    const getAuthUrl = async id => {
        setModal(true)
        setIsSubmitting(true)
        try {
            const res = await axios.get(`/api/ebay/auth/${id}`)
            if(res.status === 200) {
                setAuthUrl(res.data.data)
                setModal('auth')
            }
            setIsSubmitting(false)
        } catch(err) {
            setIsSubmitting(false)
            console.log(err)
        }
    }


    const compColumns = [
        {
            Header: 'No',
            id: "row",
            accessor: '_id',
            Cell: function Cell({row}) { 
                const urlSearchParams = new URLSearchParams(window.location.search)
                const params = Object.fromEntries(urlSearchParams.entries())
                const pages = params.page ? params.page * 20 - 20 : 0
                return <p>{row.index + 1 + pages}</p>
            }
        },
        {
            Header: 'Logo',
            accessor: 'logo',
            Cell: function Cell({value}) {
                return (
                    <div className='Timg'>
                        <img src={value ? `${process.env.REACT_APP_BACK_URL}/static/${value}` : '/img/nologo.jpg'} alt='logo' />
                    </div>
                )
            }
        },
        {
            Header: 'Name',
            accessor: 'title',
            Cell: function Cell({value}) { return <p>{value?.length > 15 ? `${value.substring(0, 15)}...` : value}</p>}
        },
        {
            Header: 'Location',
            accessor: 'location',
            Cell: function Cell({value}) { return <p>{value?.length > 15 ? `${value.substring(0, 15)}...` : value}</p>}
        },
        {
            Header: 'eBay status',
            accessor: 'ebayConnection',
            Cell: function Cell({value, row}) { return <div onClick={() => !value && getAuthUrl(row.original._id)} className={`ebayStatus ${value === true ? 'active' : 'banned'}`}>{value ? 'connected' : 'no connection'}</div>}
        },
        {
            Header: 'Edit',
            id: 'edit',
            width: 20,
            disableSortBy: true,
            Cell: function Cell({row}) {
                return <div className='editdelete' onClick={() => {
                    setSelectedImg(`url(${process.env.REACT_APP_BACK_URL}/static/${row.original.logo})`)
                    setCompanyData({...row.original, logo: null})
                    setModal('company')
                }}><img src='/img/edit.svg' alt='icon' /></div>
            }
        },
        {
            Header: 'Delete',
            id: 'delete',
            width: 20,
            disableSortBy: true,
            Cell: function Cell({row}) {
                return <div className='editdelete' onClick={() => {
                    setCompanyData(row.original)
                    setModal('delete.company')
                }}><img src='/img/delete.svg' alt='icon' /></div>
            }
        },
    ]
    const userColumns = [
        {
            Header: 'No',
            id: "row",
            accessor: '_id',
            Cell: function Cell({row}) { 
                const urlSearchParams = new URLSearchParams(window.location.search)
                const params = Object.fromEntries(urlSearchParams.entries())
                const pages = params.page ? params.page * 20 - 20 : 0
                return <p>{row.index + 1 + pages}</p>
            }
        },
        {
            Header: 'First name',
            accessor: 'firstname',
            Cell: function Cell({value}) { return <p>{value?.length > 15 ? `${value.substring(0, 15)}...` : value}</p>}
        },
        {
            Header: 'Last name',
            accessor: 'lastname',
            Cell: function Cell({value}) { return <p>{value?.length > 15 ? `${value.substring(0, 15)}...` : value}</p>}
        },
        {
            Header: 'Username',
            accessor: 'username',
            Cell: function Cell({value}) { return <p>{value?.length > 15 ? `${value.substring(0, 15)}...` : value}</p>}
        },
        {
            Header: 'Company',
            accessor: 'companies',
            Cell: function Cell({value}) { return <p>{value[0]?.title.length > 15 ? `${value[0]?.title.substring(0, 15)}...` : value[0]?.title}</p>}
        },
        {
            Header: 'Edit',
            id: 'edit',
            width: 20,
            disableSortBy: true,
            Cell: function Cell({row}) {
                return <div className='editdelete' onClick={() => {
                    setUserData({...row.original, password: 'ENCRYPTED'})
                    setModal('user')
                }}><img src='/img/edit.svg' alt='icon' /></div>
            }
        },
        {
            Header: 'Delete',
            id: 'delete',
            width: 20,
            disableSortBy: true,
            Cell: function Cell({row}) {
                return <div className='editdelete' onClick={() => {
                    setUserData(row.original)
                    setModal('delete.user')
                }}><img src='/img/delete.svg' alt='icon' /></div>
            }
        },
    ]
    
    // useEffect(() => console.log(companyData), [companyData])

    const handleDelete = async () => {
        try {
            setIsSubmitting(true)
            if(modal.split('.')[1] === 'company') {
                // const res = await axios.delete('/companies/' + companyData._id)
                await getCompanies()
            }
            if(modal.split('.')[1] === 'user') {
                // const res = await axios.delete('/users/' + userData._id)
                await getUsers()
            }
            setIsSubmitting(false)
            setModal(null)
        } catch(err) {
            console.log(err)
            setIsSubmitting(false)
            setModal(null)
        }
    }





    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: 5 }}
                open={!!modal}
                onClick={() => setModal(null)}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" />
                    : modal === 'company'
                        ? <div className={styles.modal} onClick={e => e.stopPropagation()}>
                            <div className={styles.header}>
                                <p>Company details</p>
                                <div className={styles.close} onClick={() => setModal(null)}>
                                    <img src='/img/close.svg' alt='close' />
                                </div>
                            </div>
                            <div className={styles.content}>
                                <form onSubmit={handleCompanySubmit}>
                                    <div className={styles.flexed2}>
                                        <div>
                                            <Input onChange={handleCompanyData} value={companyData.title} name='title' placeholder='Company name' />
                                            <Input onChange={handleCompanyData} value={companyData.location} name='location' placeholder='Location' />
                                            <Input onChange={handleCompanyData} value={companyData.ebayKey} name='ebayKey' placeholder='eBay API key' />
                                            <Input onChange={handleCompanyData} value={companyData.APP_ID} name='APP_ID' placeholder='APP_ID' />
                                            <Input onChange={handleCompanyData} value={companyData.CERT_ID} name='CERT_ID' placeholder='CERT_ID' />
                                            <Input onChange={handleCompanyData} value={companyData.DEV_ID} name='DEV_ID' placeholder='DEV_ID' />
                                            <Input onChange={handleCompanyData} value={companyData.RU_NAME} name='RU_NAME' placeholder='RU_NAME' />
                                            <Input onChange={handleCompanyData} value={companyData.qrType} name='qrType' type='select' placeholder='Select QR-code type'>
                                                <MenuItem value='ADV'>ADV</MenuItem>
                                                <MenuItem value='GT'>GT</MenuItem>
                                            </Input>
                                        </div>
                                        <div>
                                            <input onChange={handleImgUpload} type='file' id='logo' style={{display: 'none'}} />
                                            <label className={styles.upload} htmlFor="logo" name={selectedImg} style={{backgroundImage: selectedImg}}>
                                                <div className={styles.grayBtn}>
                                            Change
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <button type='submit' className={styles.submit}>
                                Save
                                    </button>
                                </form>
                            </div>
                        </div>
                        : modal === 'user'
                            ? <div className={styles.modal} onClick={e => e.stopPropagation()}>
                                <div className={styles.header}>
                                    <p>User details</p>
                                    <div className={styles.close} onClick={() => setModal(null)}>
                                        <img src='/img/close.svg' alt='close' />
                                    </div>
                                </div>
                                <div className={styles.content}>
                                    <form className={styles.user} onSubmit={handleUserSubmit}>
                                        <Input value={userData.firstname} name='firstname' placeholder='First name' onChange={handleUserData} />
                                        <Input value={userData.lastname} name='lastname' placeholder='Last name' onChange={handleUserData} />
                                        <Input value={userData.username} name='username' placeholder='Username' onChange={handleUserData} />
                                        <Input value={userData.password} name='password' placeholder='Password' onChange={handleUserData} />
                                        <Autocomplete
                                            multiple
                                            fullWidth
                                            id="tags-outlined"
                                            options={companies.arr}
                                            getOptionLabel={option => option.title}
                                            value={userData.companies}
                                            onChange={(event, newValue) => setUserData(prev => ({...prev, companies: newValue}))}
                                            filterSelectedOptions
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label={'Companies'}
                                                />
                                            )} />
                                        <p>Permissions</p>
                                        <div className={styles.flexed}>
                                            <div>
                                                <Input value={userData.permissions.admin} type='checkbox' name='permissions.admin' placeholder='Admin panel' onChange={handleUserData} />
                                                <Input value={userData.permissions.qr} type='checkbox' name='permissions.qr' placeholder='QR - code' onChange={handleUserData} />
                                                <Input value={userData.permissions.shipment} type='checkbox' name='permissions.shipment' placeholder='Shipment' onChange={handleUserData} />
                                            </div>
                                            <div>
                                                <Input value={userData.permissions.warehouse} type='checkbox' name='permissions.warehouse' placeholder='Warehouse' onChange={handleUserData} />
                                                <Input value={userData.permissions.sales} type='checkbox' name='permissions.sales' placeholder='Sales' onChange={handleUserData} />
                                                <Input value={userData.permissions.mobile} type='checkbox' name='permissions.mobile' placeholder='Mobile app' onChange={handleUserData} />
                                            </div>
                                        </div>
                                        <button type='submit' className={styles.submit}>
                            Save
                                        </button>
                                    </form>
                                </div>
                            </div>
                            : modal === 'auth'
                                ? <div className={styles.modal} onClick={e => e.stopPropagation()}>
                                    <div className={styles.header}>
                                        <p>New auth URL</p>
                                        <div className={styles.close} onClick={() => setModal(null)}>
                                            <img src='/img/close.svg' alt='close' />
                                        </div>
                                    </div>
                                    <div className={styles.content}>
                                        <p>Your auth accept URL: {process.env.REACT_APP_BACK_URL}{authUrl.accept}</p>
                                        <p>Your auth decline URL: {process.env.REACT_APP_BACK_URL}{authUrl.decline}</p>
                                        <p>Auth here: {authUrl.url}</p>
                                    </div>
                                </div>
                                : modal && modal.split('.')[0] === 'delete'
                && <div className={styles.modal} onClick={e => e.stopPropagation()}>
                    <div className={styles.header}>
                        <p>Delete</p>
                        <div className={styles.close} onClick={() => setModal(null)}>
                            <img src='/img/close.svg' alt='close' />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.flexed}>
                            <div className={styles.delete}>
                                <div className={styles.imgWrapper} style={{backgroundImage: modal.split('.')[1] === 'company' ? `url(${process.env.REACT_APP_BACK_URL}/static/${companyData.logo})` : 'url(/img/avatar.svg)'}} />
                                <div className={styles.text}>
                                    <p>{modal.split('.')[1] === 'company' && companyData.title}{modal.split('.')[1] === 'user' && userData.username} will be deleted</p>
                                    <span>are you sure about that?</span>
                                </div>
                            </div>
                            <button onClick={handleDelete} className={`${styles.submit} ${styles.red}`}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
                }
            </Backdrop>
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <p>Companies</p>
                        <Button onClick={() => {
                            setSelectedImg(`url(/img/logo.jpg)`)
                            setCompanyData({
                                title: '',
                                location: '',
                                ebayKey: '',
                                qrType: '',
                                logo: null
                            })    
                            setModal('company')
                        }}>+ Add company</Button>
                    </div>
                    <div className={styles.content}>
                        {companies.arr && <Table columns={compColumns} data={companies} handlePageChange={() => {}} />}
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <p>Users</p>
                        <Button onClick={() => {
                            setUserData({
                                username: '',
                                firstname: '',
                                lastname: '',
                                password: '',
                                companies: [],
                                permissions: {
                                    admin: false,
                                    qr: false,
                                    shipment: false,
                                    warehouse: false,
                                    sales: false,
                                    mobile: false
                                },
                                avatar: null
                            })
                            setModal('user')
                        }}>+ Add user</Button>
                    </div>
                    <div className={styles.content}>
                        {users.arr && <Table columns={userColumns} data={users} handlePageChange={() => {}} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admin
