import styles from './Button.module.sass'
import UiButton from '@mui/material/Button'
import React from 'react'

const Button = ({
    type = 'button',
    onClick = () => {},
    children,
    disabled
}) => {
    return <UiButton type={type} disabled={disabled ? disabled : undefined} className={styles.btn} onClick={onClick} color="primary" variant="contained" >{children}</UiButton>
}

export default Button