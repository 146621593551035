import { useState, useEffect } from 'react'

const usePrint = () => {
    var errorCallback = function (errorMessage) {
        alert("Error: " + errorMessage);
    }
    const { BrowserPrint } = window

    const [selectedPrinter, setSelectedPrinter] = useState({})
    const [printers, setPrinters] = useState([])



    const setup = () => {
        //Get the default device from the application as a first step. Discovery takes longer to complete.
        BrowserPrint.getDefaultDevice("printer", function (device) {

            //Add device to list of devices and to html select element
            let devices = []
            let selectedDevice = device
            devices.push(device)

            //Discover any other devices available to the application
            BrowserPrint.getLocalDevices(function (device_list) {
                for (var i = 0; i < device_list.length; i++) {
                    //Add device to list of devices and to html select element
                    var device = device_list[i];
                    if (!selectedDevice || device.uid !== selectedDevice.uid) {
                        devices.push(device)
                    }
                }

            }, function () { alert("Error getting local devices") }, "printer");
            setSelectedPrinter(selectedDevice)
            setPrinters(devices)
        }, function (error) {
            alert('couldnt get printer' + error)
        })
    }

    const sendFile = (fileUrl) => {
        selectedPrinter.sendFile(fileUrl, undefined, errorCallback)
    }

    useEffect(() => {
        setup()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }  , [])

    return {
        setSelectedPrinter,
        selectedPrinter,
        printers,
        sendFile
    }
}

export default usePrint