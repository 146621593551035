import { useState, useEffect, useRef } from 'react'
import { loadToken } from '../utils/storage'

export const useFetch = (url) => {
    const [state, setState] = useState({ loading: true });
    useEffect(() => console.log(url, state), [url, state])
    const promiseRef = useRef(null);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const fullUrl = process.env.REACT_APP_BACK_URL + url
        console.log('useEffect?', url)
        let promise = fetch(fullUrl, {
            headers: { Authorization: loadToken() },
            signal,
        }).then((res) => res.json())
        promiseRef.current = promise;
        setState({ loading: true })
        const handleResponse = (data, error) => {
            if (promiseRef.current === promise) {
                setState({ loading: false, data, error })
            }
        }

        promise.then(
            (data) => handleResponse(data, null),
            (error) => handleResponse(null, error));

        return () => {
            controller.abort();
            promiseRef.current = null;
        };
    }, [url]);

    return state;
};

export default useFetch;