import React from 'react';
import styles from './EditCar.module.sass'
import { useFormik } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { Button, Input } from '../';

const validate = (values) => {
    const errors = {};

    if (!/^[0-9A-Z]{17}$/i.test(values.VIN)) {
        errors.VIN = 'VIN must be 17 numbers and letters';
    }
    if (!values.make) {
        errors.make = 'Make is required';
    }
    if (!values.model) {
        errors.model = 'Model is required';
    }
    if (!/^\d{4}$/.test(values.year)) {
        errors.year = 'Year must be 4 digits';
    }
    if (!values.engine) {
        errors.engine = 'Engine is required';
    }
    if (!values.colorExt) {
        errors.colorExt = 'External color is required';
    }
    if (!values.colorInt) {
        errors.colorInt = 'Internal color is required';
    }
    if (!values.transmission) {
        errors.transmission = 'Please select transmission';
    }
    if (!values.mileage) {
        errors.mileage = 'Mileage is required';
    }
    if (!/^\d+$/.test(values.PN)) {
        errors.PN = 'Police number is required and must be a number';
    }
    return errors;
};


export const EditCar = ({ data={}, closeModal, saveCar }) => {
    const {
        errors,
        values,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: data,
        validate,
        onSubmit: saveCar,
    });
    const title = data._id ? 'Update car' : 'Create car';
    return (
        <form onSubmit={handleSubmit} className={styles.carForm}>
            <h2>{title}</h2>
            <Input placeholder='VIN number' name='VIN' value={values.VIN} error={errors.VIN} onChange={handleChange} autoFocus  />
            <Input placeholder='Make' name='make' value={values.make} error={errors.make} onChange={handleChange} />
            <Input placeholder='Model' name='model' value={values.model} error={errors.model} onChange={handleChange} />
            <Input placeholder='Year' name='year' value={values.year} error={errors.year} onChange={handleChange} />
            <Input placeholder='Engine' name='engine' value={values.engine} error={errors.engine} onChange={handleChange} />
            <Input placeholder='Transmission' name='transmission' type='select' value={values.transmission} error={errors.transmission} onChange={handleChange} >
                <MenuItem name='transmission' value='auto'>Automatic</MenuItem>
                <MenuItem name='transmission' value='manual'>Manual</MenuItem>
            </Input>
            <div className={styles.twoItems}>
                <Input placeholder='Color (ext)' name='colorExt' value={values.colorExt} error={errors.colorExt} onChange={handleChange} />
                <Input placeholder='Color (int)' name='colorInt' value={values.colorInt} error={errors.colorInt} onChange={handleChange} />
            </div>
            <Input placeholder='Mileage' name='mileage' value={values.mileage} error={errors.mileage} onChange={handleChange} />
            <Input placeholder='P/N' name='PN' value={values.PN} error={errors.PN} onChange={handleChange} />
            <div className={styles.other}>
                <Button type='submit'>Save</Button>
                <Button onClick={closeModal}>Cancel</Button>
            </div>
        </form>
    )
};

export default EditCar;