import styles from '../styles/QRPage.module.sass'
import { useParams} from 'react-router-dom'
import React, { useState, useContext, useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Button, Input } from '../components'
import QRCode from "react-qr-code"
import MaskedInput from 'react-maskedinput'
import useAxios from '../hooks/useAxios'
import { Main } from '../App'
import usePrinter from '../hooks/usePrinter'
import { Backdrop, CircularProgress } from '@mui/material'

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

const checkSku = (compData, value) => {
    const qrType = compData?.qrType
    let regex = /^([A-Z])?([0-9]{0,4})$/
    if (qrType === 'GT') {
        regex = /^([A-Z])([0-9]{0,4})$/
    } else if (qrType === 'ADV') {
        regex = /^([0-9]{0,6})$/
    }
    return regex.test(value)
}

const QRPage = () => {
    const { printers, selectedPrinter, sendFile } = usePrinter()
    // useEffect(() => {
    //     console.log('SELECTED PRINTER: ', selectedPrinter)
    // }, [selectedPrinter])
    // useEffect(() => {
    //     console.log('PRINTER: ', printers)
    // }, [printers])

    const [showSaved, setShowSaved] = useState(false)
    const { company, compData } = useContext(Main)
    const { item } = useParams()
    const axios = useAxios()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [arr, setArr] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [, setSelectedCar] = useState(null)
 
    const getData = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`/cars${showSaved ? '' : '/history'}?company=` + company)
            setArr(res.data.data.arr)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }


    const handleCarPrint = async () => {
        setIsLoading(true)
        setError({})
        let Data = data
        let Delete = ''
        if(item === 'car') Delete = 'area row bay level pos qrString'
        if(item === 'warehouse') Delete = 'VIN make model year engine transmission colorExt colorInt mileage PN countNumber quantity qrString'
        Delete.split(' ').forEach(e => delete Data[e])
        console.log(Data)
        let quit = false
        for (let i in Data) {
            let error = false
            let value = Data[i]
            if(value === '') {
                error = true
            }
            if(i === 'countNumber') {
                if (!checkSku(compData, value)) {
                    error = true
                }
            } 
            if(i === 'pos') {
                if(Number(value) > 26) value = '26'
            }
            if(i === 'area') {
                const regex = /^([A-Z0-9]{2})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            if(i === 'row' || i === 'bay') {
                const regex = /^([0-9]{2})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            if(i === 'level') {
                const regex = /^([0-9]{1})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            console.log(i)
            if(error) {
                setError(prev => ({...prev, [i]: ''}))
                quit = true
            }
                
        }
        if(quit) {
            setIsLoading(false)
            return
        }
        try {
            console.log(company)
            const res = await axios.post('/cars/print', {company: company, ...Data, size: document.getElementById('selSize').value})
            if(res.status === 200) {
                console.log(`${process.env.REACT_APP_BACK_URL}/static/${res.data.data}`)
                sendFile(`${process.env.REACT_APP_BACK_URL}/static/${res.data.data}`)
            }
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }
    const handleWarehousePrint = async () => {
        try {
            console.log(company)
            const res = await axios.post('/warehouse/print', {...data, qrString: null, size: document.getElementById('selSizeW').value})
            if(res.status === 200) {
                console.log(`${process.env.REACT_APP_BACK_URL}/static/${res.data.data}`)
                sendFile(`${process.env.REACT_APP_BACK_URL}/static/${res.data.data}`)
            }
        } catch(err) {
            console.log(err)
        }
    }


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSaved])

    const [data, setData] = useState({
        VIN: '',
        make: '',
        model: '',
        year: '',
        engine: '',
        transmission: '',
        colorExt: '',
        colorInt: '',
        mileage: '',
        PN: '',
        countNumber: '',
        quantity: '',
        area: '',
        row: '',
        bay: '',
        level: '',
        pos: '',
        qrString: null
    })

    const [error, setError] = useState({
        VIN: null,
        make: null,
        model: null,
        year: null,
        engine: null,
        transmission: null,
        colorExt: null,
        colorInt: null,
        mileage: null,
        PN: null,
        countNumber: null,
        quantity: null,
        area: null,
        row: null,
        bay: null,
        level: null,
        pos: null
    })

    // useEffect(() => console.log(error), [error])

    const getCarInfoByVin = async vin => {
        try {
            setIsLoading(true)
            const res = await axios.get(`/cars?VIN=${vin}`)
            console.log(res.data.data.cardata)
            if(res.status === 200) setData(prev => ({...prev, ...res.data.data.cardata}))
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(data?.VIN?.length === 17 && (!data.model || !data.year || !data.make || !data.transmission)) {
            getCarInfoByVin(data.VIN)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleChange = e => {
        let { name, value } = e.target
        if(name === 'VIN') if(value.length > 17) return
        if(name === 'transmission') {
            if(error.transmission !== null) setError(prev => ({...prev, transmission: null}))
            return setData(prev => ({...prev, [name]: value}))
        }
        value = value.toUpperCase()
        if(name === 'pos') {
            const regex = /^([0-9]{0,2})$/
            if(!regex.test(value)) return
            if(Number(value) > 26) value = '26'
            if(value === '0') value = '1'
        }
        if(name === 'area') {
            const regex = /^([A-Z0-9]{0,2})$/
            if(!regex.test(value)) return
        }
        if(name === 'row' || name === 'bay') {
            const regex = /^([0-9]{0,2})$/
            if(!regex.test(value)) return
        }
        if(name === 'level') {
            const regex = /^([0-9]?)$/
            if(!regex.test(value)) return
        }
        if(name === 'countNumber') {
            if(!checkSku(compData, value)) {
                return
            }
        }
        setData(prev => ({...prev, [name]: value}))
    }

    const handleFocus = e => {
        let { name } = e.target
        console.log(name)
        if(error[name] !== null) setError(prev => ({...prev, [name]: null}))
    }





    const handleSubmit = async e => {
        e.preventDefault()
        setError({})
        let Data = data
        let Delete = ''
        if(item === 'car') Delete = 'area row bay level pos qrString'
        if(item === 'warehouse') Delete = 'VIN make model year engine transmission colorExt colorInt mileage PN countNumber quantity qrString'
        Delete.split(' ').forEach(e => delete Data[e])
        console.log(Data)
        let quit = false
        for(let i in Data) {
            let error = false 
            let value = Data[i]
            if(value === '') error = true
            if(i === 'countNumber') {
                if(!checkSku(compData, value)) {
                    error = true
                }
            }
            if(i === 'pos') {
                if(Number(value) > 26) value = '26'
            }
            if(i === 'area') {
                const regex = /^([A-Z0-9]{2})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            if(i === 'row' || i === 'bay') {
                const regex = /^([0-9]{2})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            if(i === 'level') {
                const regex = /^([0-9]{1})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            console.log(i)
            if(error) {
                setError(prev => ({...prev, [i]: ''}))
                quit = true
            }
                
        }
        if(quit) return
        try {
            setIsSubmitting(true)
            const res = await axios.post(`/cars?comp=${company}`, Data)
            console.log(res)
            console.log('res')
            getData()
            setIsSubmitting(false)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsSubmitting(false)
            setIsLoading(false)
        }
    }

    const handleSetHistory = item => {
        const { VIN, make, model, year, engine, transmission, colorExt, colorInt, mileage, PN, countNumber, quantity, _id } = item
        setSelectedCar(_id)
        setData(prev => ({...prev,
            VIN,
            make,
            model,
            year,
            engine,
            transmission,
            colorExt,
            colorInt,
            mileage,
            PN,
            quantity,
            countNumber
        }))
    }

    const handleWarehousePrintOne =  async () => {
        try {
            console.log(company)
            const res = await axios.post('/warehouse/print', {qrString: data.qrString, size: document.getElementById('selSizeW1').value})
            if(res.status === 200) {
                console.log(`${process.env.REACT_APP_BACK_URL}/static/${res.data.data}`)
                sendFile(`${process.env.REACT_APP_BACK_URL}/static/${res.data.data}`)
            }
        } catch(err) {
            console.log(err)
        }
    }





    const generateLabel = async () => {
        setError({})
        let Data = data
        let Delete = ''
        if(item === 'car') Delete = 'area row bay level pos qrString'
        if(item === 'warehouse') Delete = 'VIN make model year engine transmission colorExt colorInt mileage PN countNumber quantity qrString'
        Delete.split(' ').forEach(e => delete Data[e])
        let quit = false
        for(let i in Data) {
            let error = false
            let value = Data[i]
            if(value === '') error = true
            if(i === 'countNumber') {
                if(!checkSku(compData, value)) {
                    error = true
                }
            }
            if(i === 'pos') {
                if(Number(value) > 26) value = '26'
            }
            if(i === 'area') {
                const regex = /^([A-Z0-9]{2})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            if(i === 'row' || i === 'bay') {
                const regex = /^([0-9]{2})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            if(i === 'level') {
                const regex = /^([0-9]{1})$/
                if(!regex.test(value)) {
                    console.log(regex.test(value))
                    error = true
                }
            }
            console.log(i)
            if(error) {
                setError(prev => ({...prev, [i]: ''}))
                quit = true
            }
                
        }
        if(quit) return
        try {
            setIsSubmitting(true)
            const res = await axios.post(`/cars/history?comp=${company}`, Data)
            console.log(res)
            console.log('res')
            getData()
            setIsSubmitting(false)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsSubmitting(false)
            setIsLoading(false)
        }

        // document.getElementById('asdasd').src=res
    }

    return(
        <div className={styles.container}>
            <Backdrop
                sx={{ color: '#fff', zIndex: 5 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {item === 'car' && <>
                <div className={styles.side}>
                    <p className={styles.title} style={{cursor: 'pointer'}} onClick={() => setShowSaved(prev => !prev)}>{showSaved ? 'Saved' : 'History'}</p>
                    <div className={styles.card}>
                        <div className={styles.hItem} key={item._id}>
                            <p style={{color: '#000', cursor: 'default'}}>Make</p>
                            <p>Model</p>
                            <p>Year</p>
                            <p>Begin No</p>
                            <p>End No</p>
                        </div>
                        {arr.length === 0 ? 'No history'
                            : arr.map(item =>
                                <div className={styles.hItem} key={item._id}>
                                    <p onClick={() => handleSetHistory(item)}>{item.make}</p>
                                    <p>{item.model}</p>
                                    <p>{item.year}</p>
                                    <p>{item.countNumber}</p>
                                    <p>{item.endNumber}</p>
                                </div>)}
                    </div>
                </div>
                <img id='asdasd' src='' alt='img' />
                <form onSubmit={handleSubmit} className={styles.data}>
                    <p>Car data</p>
                    <Input onFocus={handleFocus} placeholder='VIN number' name='VIN' value={data.VIN} error={error.VIN} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Make' name='make' value={data.make} error={error.make} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Model' name='model' value={data.model} error={error.model} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Year' name='year' value={data.year} error={error.year} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Engine' name='engine' value={data.engine} error={error.engine} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Transmission' name='transmission' type='select' value={data.transmission} error={error.transmission} onChange={handleChange} >
                        <MenuItem name='transmission' value='auto'>Automatic</MenuItem>
                        <MenuItem name='transmission' value='manual'>Manual</MenuItem>
                    </Input>
                    <div className={styles.twoItems}>
                        <Input onFocus={handleFocus} placeholder='Color (ext)' name='colorExt' value={data.colorExt} error={error.colorExt} onChange={handleChange} />
                        <Input onFocus={handleFocus} placeholder='Color (int)' name='colorInt' value={data.colorInt} error={error.colorInt} onChange={handleChange} />
                    </div>
                    <Input onFocus={handleFocus} placeholder='Mileage' name='mileage' value={data.mileage} error={error.mileage} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='P/N' name='PN' value={data.PN} error={error.PN} onChange={handleChange} />
                    <div className={styles.other}>
                        <div className={styles.twoItems}>
                            <Input onFocus={handleFocus} placeholder='Count number' name='countNumber' value={data.countNumber} error={error.countNumber} onChange={handleChange} />
                            <Input onFocus={handleFocus} placeholder='Quantity' name='quantity' value={data.quantity} error={error.quantity} onChange={handleChange} />
                        </div>
                        <Button disabled={isSubmitting} onClick={generateLabel}>Generate label</Button>
                        {/*<Button disabled={isSubmitting} type='submit'>Save</Button>*/}
                    </div>
                </form>
                <div className={styles.side}>
                    <p className={styles.title}>Live preview</p>
                    <div className={styles.card}>
                        <div className={styles.sticker}>
                            <div className={styles.info}>
                                <div className={styles.company}>
                                    <p>{compData?.title}</p>
                                    <span>{compData?.location}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Make</p>
                                    <span>{data.make}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Model</p>
                                    <span>{data.model}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Year</p>
                                    <span>{data.year}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Engine</p>
                                    <span>{data.engine}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Transm</p>
                                    <span>{data.transmission === 'auto' ? 'AUTOMATIC' : data.transmission === 'manual' ? 'MANUAL' : ''}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Color</p>
                                    <span>{data.colorExt} {data.colorInt}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Mileage</p>
                                    <span>{data.mileage}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>P/N</p>
                                    <span>{data.PN}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>VIN</p>
                                    <span>{data.VIN}</span>
                                </div>
                            </div>
                            <div className={styles.qr}>
                                <QRCode value={data.countNumber ? data.countNumber : ''} size={120} />
                                <div className={styles.text}>
                                    {data.countNumber && [...data.countNumber].map((item, key) => <p key={key}>{item}</p>)}
                                </div>
                            </div>
                        </div>
                        <div className={styles.print}>
                            <div>
                                <label htmlFor='selDevice'>Selected Device:</label>
                                <select id='selDevice' value={selectedPrinter.uid}>
                                    {printers.map(item => <option value={item.uid} key={item.uid}>{item.name}</option>)}
                                </select>
                            </div>
                            <div>
                                <label htmlFor='selSize'>Select label size:</label>
                                <select id='selSize'>
                                    <option value='3.2x1.6'>3.2x1.6</option>
                                    <option value='3x2'>3x2</option>
                                    <option value='4x2'>4x2</option>
                                </select>
                            </div>
                            <Button onClick={handleCarPrint}>Print</Button>
                        </div>
                    </div>
                </div>
            </>}
            {item === 'warehouse' && <>
                <div className={styles.side}>
                    <p className={styles.title}>One label</p>
                    <div className={styles.card}>
                        <div className={styles.manual}>
                            <p className={styles.placeholdertitles}>AREA - ROW - BAY - LEVEL - POS</p>
                            <MaskedInput className={styles.maskedInput} mask='WW  -  11  -  11  -  1  -  A' name='qrString' value={data.qrString} onChange={handleChange} placeholder={'A0   -   01   -   02   -   3   -   A'}
                                formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                }} />
                        </div>
                        <div className={styles.print}>
                            <div>
                                <label htmlFor='selDevice'>Selected Device:</label>
                                <select id='selDevice' value={selectedPrinter.uid}>
                                    {printers.map(item => <option value={item.uid} key={item.uid}>{item.name}</option>)}
                                </select>
                            </div>
                            <div>
                                <label htmlFor='selSizeW1'>Select label size:</label>
                                <select id='selSizeW1'>
                                    <option value='4x2'>4x2</option>
                                    <option value='4x6'>4x6</option>
                                </select>
                            </div>
                            <Button onClick={handleWarehousePrintOne}>Print</Button>
                        </div>
                    </div>
                </div>
                <form onSubmit={e => e.preventDefault()} className={styles.data}>
                    <p>Labels of the bay</p>
                    <Input onFocus={handleFocus} placeholder='Area name' name='area' value={data.area} error={error.area} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Row number' name='row' value={data.row} error={error.row} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Bay number' name='bay' value={data.bay} error={error.bay} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Quantity levels' name='level' value={data.level} error={error.level} onChange={handleChange} />
                    <Input onFocus={handleFocus} placeholder='Quantity posts' name='pos' value={data.pos} error={error.pos} onChange={handleChange} />
                    <div className={styles.other}>
                        <Button type='submit'>Generate label</Button>
                    </div>
                </form>
                <div className={styles.side}>
                    <p className={styles.title}>Live preview</p>
                    <div className={styles.card}>
                        <div className={styles.sticker}>
                            {item === 'car' && <div className={styles.info}>
                                <div className={styles.company}>
                                    <p>ADV Used Parts LLC</p>
                                    <span>Traverse City, MI</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Make</p>
                                    <span>{data.make}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Model</p>
                                    <span>{data.model}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Year</p>
                                    <span>{data.year}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Engine</p>
                                    <span>{data.engine}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Transm</p>
                                    <span>{data.transmission}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Color</p>
                                    <span>{data.colorExt} {data.colorInt}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>Mileage</p>
                                    <span>{data.mileage}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>P/N</p>
                                    <span>{data.PN}</span>
                                </div>
                                <div className={styles.item}>
                                    <p>VIN</p>
                                    <span>{data.VIN}</span>
                                </div>
                            </div>}
                            <div className={`${styles.qr} ${styles.ware}`}>
                                <QRCode value={`${data.area}-${data.row}-${data.bay}-${data.level}-${alphabet[data.pos -1]}`} size={120} />
                                <div className={styles.titles}>
                                    <p className={styles.S}>area</p>
                                    <p className={styles.L}>row</p>
                                    <p className={styles.L}>bay</p>
                                    <p className={styles.B}>level</p>
                                    <p className={styles.S}>pos</p>
                                </div>
                                <div className={styles.text}>
                                    <p className={styles.L}>{data.area ? data.area.length === 1 ? `0${data.area}` : data.area.length === 2 ? data.area : 'XX' : 'XX'}</p>
                                    -
                                    <p className={styles.L}>{data.row ? data.row.length === 1 ? `0${data.row}` : data.row.length === 2 ? data.row : 'XX' : 'XX'}</p>
                                    -
                                    <p className={styles.L}>{data.bay ? data.bay.length === 1 ? `0${data.bay}` : data.bay.length === 2 ? data.bay : 'XX' : 'XX'}</p>
                                    -
                                    <p className={styles.B}>{data.level ? data.level.length > 0 ? data.level : 'X' : 'X'}</p>
                                    -
                                    <p className={styles.S}>{data.pos ? data.pos.length > 0 ? alphabet[data.pos -1] : 'X' : 'X'}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.print}>
                            <div>
                                <label htmlFor='selDevice'>Selected Device:</label>
                                <select id='selDevice' value={selectedPrinter.uid}>
                                    {printers.map(item => <option value={item.uid} key={item.uid}>{item.name}</option>)}
                                </select>
                            </div>
                            <div>
                                <label htmlFor='selSizeW'>Select label size:</label>
                                <select id='selSizeW'>
                                    <option value='4x2'>4x2</option>
                                    <option value='4x6'>4x6</option>
                                </select>
                            </div>
                            <Button onClick={handleWarehousePrint}>Print</Button>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default QRPage
