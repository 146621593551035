import React, { useState } from 'react'
import styles from '../styles/Cars.module.sass'
import Table from '../components/Table/Table'
import { useFetch } from '../hooks/useFetch';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button } from '../components';
import EditCar from '../components/EditCar/EditCar';
import { loadToken } from '../utils/storage'

const defaultData = {
    VIN: '',
    make: '',
    model: '',
    year: '',
    engine: '',
    transmission: '',
    colorExt: '',
    colorInt: '',
    mileage: '',
    PN: '',
};

const carColumns = [
    {
        id: "make",
        accessor: 'make',
        Header: () => 'Make',
    },
    {
        id: "model",
        accessor: 'model',
        Header: () => 'Model',
    },
    {
        id: "year",
        accessor: 'year',
        Header: () => 'Year',
    },
    {
        id: "VIN",
        accessor: 'VIN',
        Header: () => 'VIN',
    },
    {
        id: "PN",
        accessor: 'PN',
        Header: () => 'PN',
    },
    {
        id: "mileage",
        accessor: 'mileage',
        Header: () => 'Mileage',
    },
    {
        id: "transmission",
        accessor: 'transmission',
        Header: () => 'Transmission',
    },
    {
        id: "color",
        accessor: ({ colorExt, colorInt }) => `${colorExt} / ${colorInt}`,
        Header: () => 'Color ext/int',
    },
    {
        id: "action",
        accessor: '_id',
        Header: () => 'Edit',
        Cell: ({ value }) => <EditColumn id={value} />
    },
];

const EditContext = React.createContext();

const EditColumn = ({ id }) => {
    const { editCar } = React.useContext(EditContext);
    return (
        <Button onClick={() => editCar(id)}>
            Edit
        </Button>
    );
};

const columnOrder = ['make', 'model', 'year', 'VIN', 'PN', 'mileage', 'transmission', 'color'];

const saveCarApi = async (data) => {
    const fullUrl = process.env.REACT_APP_BACK_URL + '/cars/car'

    const response = await fetch(fullUrl, {
        headers: {
            Authorization: loadToken(),
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
    });
    return await response.json();
}

const Cars = () => {
    const [ts, setTs] = useState(+new Date());
    const { data, loading } = useFetch('/cars/list?ts=' + ts);
    const [modalOpen, setModalOpen] = useState(false);
    const [carData, setCarData] = useState(null);
    // useEffect(() => console.log(data&& data.items), [data])

    if (loading || !data) {
        return 'Loading...';
    }

    const closeModal = () => setModalOpen(false);
    const createCar = () => {
        setCarData({ ...defaultData });
        setModalOpen(true);
    };
    const editCar = (id) => {
        setCarData(data.items.find((car) => car._id === id));
        setModalOpen(true);
    };
    const saveCar = async (carData) => {
        console.log('Saving car data', carData);
        const response = await saveCarApi(carData)
        console.log('save response:', response);
        setTs(+new Date());
        setModalOpen(false);
    };

    return (
        <EditContext.Provider value={{ editCar }}>
            <Modal className={styles.editCarModal} open={modalOpen} onClose={closeModal}>
                <Box>
                    <EditCar data={carData} closeModal={closeModal} saveCar={saveCar} />
                </Box>
            </Modal>
            <Button onClick={createCar}>Create Car</Button>
            <Table hide={[]} perPage={20} setPerPage={() => {}}
                columnOrder={columnOrder} columns={carColumns}
                data={{arr: data.items}} handlePageChange={() => {}}
            />
        </EditContext.Provider>
    );
}

export default Cars;