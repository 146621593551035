import { useState, useEffect } from 'react'
import useAxios from './useAxios'

import { loadToken, removeToken, saveToken } from '../utils/storage'

export const useAuth = () => {
    const axios = useAxios()
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => setUser(loadToken()), [])

    const login = async (data) => {
        try {
            setIsLoading(true)
            const res = await axios.post('/auth', data)
            if(res.data.status === 'success') {
                saveToken(res.data.token)
                setUser(res.data.token)
                window.location.href = `/`
            } else if(res.data.status === 'error') {
                setIsLoading(false)
                return res.data.message
            }
        } catch(err) {
            setIsLoading(false)
            console.log(err)
            return err.response.data
        }
    }

    const logout = async () => {
        setIsLoading(true)
        removeToken()
        window.location.href = `/auth`
        setIsLoading(false)
    }

    return {
        user, isLoading, login, logout
    }
}

export default useAuth
