import styles from './InputSearch.module.sass'
import React, { useState, useEffect } from 'react'

const InputSearch = ({ handleSearch}) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(value !== '') handleSearch(value)
        }, 300)
    
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return(
        <div className={styles.input}>
            <img src='/img/search.svg' alt='search' />
            <input value={ value} onChange={e => setValue(e.target.value)} placeholder='Search...' />
        </div>
    )
}

export default InputSearch