/* eslint-disable no-unused-vars */
import styles from '../styles/Warehouse.module.sass'
import React, { useState, useEffect, useContext } from 'react'
import {  MenuItem, CircularProgress, Backdrop} from '@mui/material'
import { Button, Input } from '../components'
import useAxios from '../hooks/useAxios'
import Table from '../components/Table/Table'
import { Main } from '../App'
import InputSearch from '../components/InputSearch/InputSearch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Moment from 'react-moment'

const Warehouse = () => {
    const axios = useAxios()
    const { company } = useContext(Main)
    const [perPage, setPerPage] = useState('200')
    const [modal, setModal] = useState(null)
    const [users, setUsers] = useState([])
    const [history, setHistory] = useState([])
    const [tasks, setTasks] = useState([])
    const [search, setSearch] = useState('')
    const [products, setProducts] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedHistoryObj, setSelectedHistoryObj] = useState('')
    const [selected, setSelected] = useState([])
    const [taskData, setTaskData] = useState({
        executor: '',
        action: ''
    })
    const [archive, setArchive] = useState(false)
    const [hide, setHide] = useState([])
    const [columnOrder, setColumnOrder] = useState(['row', 'checked', 'settings'])
    const [settingsData, setSettingsData] = useState(JSON.parse(localStorage.getItem('settingsData')) || [
        { id: 'company.title', visible: true, title: 'Company', order: 0 },
        { id: 'warehouseLocation', visible: true, title: 'Warehouse location', order: 1 },
        { id: 'SKU', visible: true, title: 'SKU', order: 2 },
        { id: 'car', visible: true, title: 'Car', order: 3 },
        { id: 'ebayData.images', visible: true, title: 'Image', order: 4 },
        { id: 'ebayData.title', visible: true, title: 'eBay title', order: 5 },
        { id: 'status', visible: true, title: 'Status', order: 6 },
        { id: 'ebayData.ItemSpecifics', visible: true, title: 'Manufacture number', order: 7 },
        { id: 'history', visible: true, title: 'History', order: 8 },
    ])
    

    useEffect(() => {
        localStorage.setItem('settingsData', JSON.stringify(settingsData))
        setHide(prev => ([...settingsData.map(item => !item.visible ? item.id : ''), ...(archive ? [] : ['soldAt', 'soldWhere', 'shipping'])]))
        setColumnOrder(prev => ([...['row', 'checked', ...settingsData.sort((a, b) => a.order < b.order ? -1 : 1).map(item => item.id), 'soldAt', 'soldWhere', 'shipping', 'settings']]))
    }, [settingsData, archive])

    const getUsers = async () => {
        try {
            const res = await axios.get(`/users?company=${company}`)
            if(res.status === 200) setUsers(res.data.data.arr)
        } catch(err) {
            console.log(err)
        }
    }

    const getHistory = async id => {
        try {
            const res = await axios.get(`/history/${id}?perPage=10`)
            if(res.status === 200) setHistory(res.data.data)
        } catch(err) {
            console.log(err)
        }
    }
    const handlePageChangeHistory = async page => {
        try {
            console.log(products.pageIndex, page, products.pageIndex === page)
            if(products.pageIndex !== page) {
                const res = await axios.get(`/history/${selectedHistoryObj}?pageIndex=${page}&perPage=10`)
                if(res.status === 200) {
                    setHistory(prev => res.data.data)
                    window.scrollTo({
                        top: 0, 
                        behavior: 'smooth'
                    })
                }
            }

        } catch(err) {
            console.log(err)
        }
    }

    const getTasks = async () => {
        try {
            const res = await axios.get(`/tasks?company=${company}&perPage=10&sortType=new`)
            if(res.status === 200) setTasks(res.data.data)
        } catch(err) {
            console.log(err)
        }
    }
    const handlePageChangeTasks = async page => {
        try {
            console.log(tasks.pageIndex, page, tasks.pageIndex === page)
            if(tasks.pageIndex !== page) {
                const res = await axios.get(`/tasks?company=${company}&pageIndex=${page}&perPage=10`)
                if(res.status === 200) {
                    setTasks(prev => res.data.data)
                    window.scrollTo({
                        top: 0, 
                        behavior: 'smooth'
                    })
                }
            }

        } catch(err) {
            console.log(err)
        }
    }

    const getProducts = async () => {

        try {
            const res = await axios.get(`/products?company=${company}${archive ? '&archive=true' : ''}&perPage=${perPage}`)
            if(res.status === 200) {
                setProducts(prev => res.data.data)
            }

        } catch(err) {
            console.log(err)
        }
    }
    
    // useEffect(() => console.log(products), [products])

    const handlePageChangeProducts = async page => {
        try {
            setIsSubmitting(true)
            setSelected([])
            if(products.pageIndex !== page) {
                const res = await axios.get(`/products?company=${company}${search && `&search=${search}`}&pageIndex=${page || 1}${archive ? '&archive=true' : ''}&perPage=${perPage}`)
                if(res.status === 200) {
                    setProducts(prev => res.data.data)
                    window.scrollTo({
                        top: 0, 
                        behavior: 'smooth'
                    })
                }
            }
            setIsSubmitting(false)

        } catch(err) {
            setIsSubmitting(false)
            console.log(err)
        }
    }
    
    useEffect(() => {
        handlePageChangeProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    // const handleProductsSearch = async search => {

    //     try {
    //         const res = await axios.get(`/products?company=${company}&search=${search}${archive ? '&archive=true' : ''}&perPage=${perPage}`)
    //         if(res.status === 200) setProducts(prev => res.data.data)

    //     } catch(err) {
    //         console.log(err)
    //     }
    // }

    // useEffect(() => console.log(selected), [selected])

    useEffect(() => {
        getProducts()
        getUsers()
        getTasks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [archive, perPage])

    const warehouseColumns = [
        {
            id: "checked",
            accessor: '_id',
            Header: function Header() {
                return <input type='checkbox' checked={selected.length === products.arr.length} className={'checkbox'} onChange={e => {
                    if(selected.length === products.arr.length) setSelected([])
                    else {
                        let arr = products.arr.map(item => item._id)
                        setSelected(arr)
                    }
                }}/>
            },
            disableSortBy: true,
            Cell: function Cell({value, row}) { 
                return <input type='checkbox' checked={selected.includes(value)} className={'checkbox'} onChange={e => {
                    const { checked } = e.target
                    if(checked) setSelected(prev => ([...prev, value]))
                    else {
                        let arr = selected
                        const index = arr.findIndex(item => item === value)
                        arr.splice(index, 1)
                        setSelected(prev => ([...arr]))
                    }
                }}/>
            }
        },
        {
            Header: 'No',
            id: "row",
            accessor: '_id',
            Cell: function Cell({value, row}) { 
                // const urlSearchParams = new URLSearchParams(window.location.search)
                // const params = Object.fromEntries(urlSearchParams.entries())
                // const pages = params.page ? params.page * 20 - 20 : 0
                const pages2 = products.pageIndex ? products.pageIndex * Number(perPage) - Number(perPage) : 0
                return <p>{row.index + 1 + pages2}</p>
            }
        },
        {
            Header: 'Company',
            accessor: 'company.title',
            Cell: function Cell({value, row}) { return <p>{value?.length > 30 ? `${value.substring(0, 30)}...` : value}</p>}
        },
        {
            Header: 'Warehouse location',
            accessor: 'warehouseLocation',
            Cell: function Cell({value, row}) { return <p>{value ? value : 'NO LOCATION'}</p>}
        },
        {
            Header: 'SKU',
            accessor: 'SKU',
            Cell: function Cell({value, row}) { return <p>{value}</p>}
        },
        {
            Header: 'Car',
            accessor: 'car',
            disableSortBy: true,
            Cell: function Cell({value, row}) { return <div style={{minWidth:200}}><p>{value?.make} {value?.model} {value?.year}</p><p>{value?.engine} {value?.mileage} {value?.PN}</p><p>{value?.VIN}</p></div>}
        },
        {
            Header: 'Image',
            disableSortBy: true,
            accessor: 'ebayData.images',
            Cell: function Cell({value, row}) {
                return <div className='Timg2'>
                    <img src={ (value && value[0]) || '/img/nologo.jpg'} alt='img' />
                </div>
            }
        },
        {
            Header: 'eBay title',
            accessor: 'ebayData.title',
            width: 250,
            Cell: function Cell({value, row}) { return <div className='ebayTitle'><a target='_blank' rel="noreferrer" href={`https://www.ebay.com/itm/${row.original?.ItemID}`}>{value || ''}</a><p>{row.original.ItemID}</p></div>}
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: function Cell({value, row}) { return value.replace('_', ' ')}
        },
        {
            Header: 'Manufacture number',
            disableSortBy: true,
            accessor: 'ebayData.ItemSpecifics',
            Cell: function Cell({value, row}) {
                return <p>
                    {(value || []).find(item => item.Name === 'Manufacturer Part Number')?.Value}
                </p>
            }
        },
        {
            Header: 'Date of sale',
            accessor: 'soldAt',
            Cell: function Cell({value, row}) { return <>{value && <Moment format="YYYY/MM/DD">{value}</Moment>}</>}
        },
        {
            Header: 'Sales platform',
            disableSortBy: true,
            id: 'soldWhere',
            Cell: function Cell({value, row}) { return 'eBay'}
        },
        {
            Header: 'Shipping & delivery services',
            disableSortBy: true,
            id: 'shipping',
            Cell: function Cell({value, row}) { return 'xxx'}
        },
        {
            Header: 'History',
            id: 'history',
            disableSortBy: true,
            accessor: '_id',
            width: 20,
            // disableSortBy: true,
            Cell: function Cell({value, row}) {
                return <div className='editdelete' onClick={async () => {
                    setSelectedHistoryObj(prev => (value))
                    await getHistory(value)
                    setModal('history')
                }}><img src='/img/history.svg' alt='img' /></div>
            }
        },
        {
            disableSortBy: true,
            Header: function Header() { return <img src='/img/settings.svg' className='settings' onClick={() => setModal('settings')} alt='settig' />},
            id: 'settings',
            width: 0,
        },
    ]
    const tasksColumns = [
        {
            Header: 'Task',
            accessor: 'action'
        },
        {
            Header: "Item",
            accessor: 'item.ebayData.title',
            Cell: function Cell({value, row}) { return <p>{value?.length > 30 ? `${value.substring(0, 30)}...` : value}</p>}
        },
        {
            Header: "SKU",
            accessor: 'item.SKU',
            Cell: function Cell({value, row}) { return <p>{value}</p>}
        },
        {
            Header: 'Worker',
            accessor: 'executor',
            Cell: function Cell({value, row}) { return <p>{value.firstname && value.lastname ? `${value.firstname} ${value.lastname}` : value.username}</p>}
        },
        {
            Header: 'Time has passed',
            accessor: 'createdAt',
            Cell: function Cell({value, row}) { return <Moment fromNow>{value}</Moment>}
        },
        {
            id: 'delete',
            disableSortBy: true,
            accessor: '_id',
            // disableSortBy: true,
            Cell: function Cell({value, row}) {
                return <div className='editdelete delete' onClick={async () => {
                    try {
                        const res = await axios.delete(`tasks/${value}`)
                        if(res.status === 200) await getTasks()
                    } catch {
                        return null
                    }
                }}><div className='help'>Delete</div><img src='/img/delete.svg' alt='icon' /></div>
            }
        },
    ]
    const historyColumns = [
        {
            Header: 'Date',
            id: 'date',
            accessor: 'createdAt',
            Cell: function Cell({value, row}) { return <p><Moment format='DD/MM/YYYY'>{value}</Moment></p>}
        },
        {
            Header: "Time",
            id: 'time',
            accessor: 'createdAt',
            Cell: function Cell({value, row}) { return <p><Moment format='HH:mm'>{value}</Moment></p>}
        },
        {
            Header: "Username",
            accessor: 'executor.username',
            Cell: function Cell({value, row}) { return value ? value : ''}
        },
        {
            Header: 'Action',
            accessor: 'action',
        },
        {
            Header: 'Details',
            accessor: 'details',
        },
    ]
    const settingsColumns = [
        {
            Header: 'Place in the table',
            accessor: 'id',
            id: 'settingsDND',
            disableSortBy: true,
            Cell: function Cell({value, row}) { return <div className={'dndButton'}>move</div>}
        },
        {
            Header: "Show column",
            accessor: 'visible',
            disableSortBy: true,
            Cell: function Cell({value, row}) {
                return <input type='checkbox' checked={settingsData.find(item => item.id === row.original.id).visible} onChange={e => {
                    setSettingsData(prev => {
                        const index = prev.findIndex(item => item.id === e.target.name)
                        prev[index] = {...prev[index], visible: e.target.checked}
                        return [...prev]
                    })
                }} name={row.original.id} id={row.original.id} />
            }
        },
        {
            Header: "Column name",
            accessor: 'title',
            disableSortBy: true,
        },
    ]

    const handleTaskSubmit = async e => {
        e.preventDefault()
        try {
            setIsSubmitting(true)
            if(selected.length === 0) return
            if(taskData.action === '' || taskData.executor === '') return
            let res = null
            for(let i in selected) {
                res = await axios.post('/tasks', {
                    comp: company,
                    action: taskData.action,
                    executor: taskData.executor,
                    item: selected[i]
                })
            }
            if(res.status === 200) {
                setIsSubmitting(false)
                setModal(null)
                await getTasks()
            } else alert('Error!')
        } catch(err) {
            setIsSubmitting(false)
            console.log(err)
        }
    }

    const handleDragChange = arr => {
        let settings = settingsData
        for(let i in arr) {
            const index = arr.findIndex(item => item.id === settings[i].id)
            settings[i].order = index
        }
        setSettingsData(prev => ([...settings]))
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: 5 }}
                open={!!modal || !!isSubmitting}
                onClick={() => setModal(null)}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" />
                    : modal === 'newTask'
                        ? <div className={styles.modal} onClick={e => e.stopPropagation()}>
                            <div className={styles.header}>
                                <p>Create task</p>
                                <div className={styles.close} onClick={() => setModal(null)}>
                                    <img src='/img/close.svg' alt='close' />
                                </div>
                            </div>
                            <div className={styles.content}>
                                <form className={styles.user} onSubmit={handleTaskSubmit}>
                                    <p>{selected.length} {selected.length === 1 ? 'item' : 'items'} selected</p>
                                    <Input type='select' placeholder='Select an action' name='action' value={taskData.action} onChange={e => setTaskData(prev => ({...prev, action: e.target.value}))} >
                                        <MenuItem value='garbage'>Garbage</MenuItem>
                                    </Input>
                                    <Input type='select' placeholder='Select an employee' name='executor' value={taskData.executor} onChange={e => setTaskData(prev => ({...prev, executor: e.target.value}))} >
                                        {users.map(item => <MenuItem key={item._id} value={item._id}>{item.firstname && item.lastname ? `${item.firstname} ${item.lastname}` : item.username}</MenuItem>)}
                                    </Input>
                                    <button type='submit' className={styles.submit}>
                                Save
                                    </button>
                                </form>
                            </div>
                        </div> : modal === 'currentTasks'
                            ? <div className={styles.modal} onClick={e => e.stopPropagation()}>
                                <div className={styles.header}>
                                    <p>Current tasks</p>
                                    <div className={styles.close} onClick={() => setModal(null)}>
                                        <img src='/img/close.svg' alt='close' />
                                    </div>
                                </div>
                                <div className={styles.content} style={{width: 700}}>
                                    {tasks.arr && <Table white columns={tasksColumns} data={tasks} handlePageChange={handlePageChangeTasks} />}
                                </div>
                            </div> : modal === 'history'
                                ? <div className={styles.modal} onClick={e => e.stopPropagation()}>
                                    <div className={styles.header}>
                                        <p>History</p>
                                        <div className={styles.close} onClick={() => setModal(null)}>
                                            <img src='/img/close.svg' alt='close' />
                                        </div>
                                    </div>
                                    <div className={styles.content} style={{width: 700}}>
                                        {history.arr && <Table white columns={historyColumns} data={history} handlePageChange={handlePageChangeHistory} />}
                                    </div>
                                </div> : modal === 'settings'
                && <div className={styles.modal} onClick={e => e.stopPropagation()}>
                    <div className={styles.header}>
                        <p>Warehouse table settings</p>
                        <div className={styles.close} onClick={() => setModal(null)}>
                            <img src='/img/close.svg' alt='close' />
                        </div>
                    </div>
                    <div className={styles.content} style={{width: 700}}>
                        <Table beige columns={settingsColumns} data={{arr: settingsData}} handleDragChange={handleDragChange} />
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <button onClick={() => window.location.reload()} className={styles.submit}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                }
            </Backdrop>
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <p>Warehouse</p>
                        <InputSearch handleSearch={setSearch} />
                        <div className={styles.right}>
                            <FormControlLabel
                                value={archive}
                                sx={{opacity: archive ? 1 : 0.5, '.MuiTypography-root': {fontSize: 13}}}
                                control={
                                    <Switch
                                        checked={archive}
                                        onChange={(event, value) => setArchive(value)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="show archival records"
                                labelPlacement="start"
                            />
                            <div className={`${styles.taskMenu} ${selected.length === 0 ? styles.disabled : ''}`}>
                                <span className={styles.help}>Use check box to select articles from table</span>
                                <Button disabled={selected.length === 0} onClick={() => {
                                    setModal('newTask')
                                }}>Create task</Button>
                                <div className={styles.letterBtn} onClick={() => {
                                    setModal('currentTasks')
                                }}>{`Current ${tasks?.count || ''} tasks`}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        {products.arr && <Table hide={hide} perPage={perPage} setPerPage={setPerPage} columnOrder={columnOrder} columns={warehouseColumns} data={products} handlePageChange={handlePageChangeProducts} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Warehouse
