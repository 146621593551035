import { Header } from './components'
// import useAxios from 'hooks/useAxios'
import { useFetch } from './hooks/useFetch'
import Auth from './pages/Auth'
import React, { createContext, useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom"
import SelectCompany from './pages/SelectCompany'
import QRPage from './pages/QRPage'
import Cars from './pages/Cars'
import Admin from './pages/Admin'
import Warehouse from './pages/Warehouse'

export const Main = createContext()

const App = () => {
    const {
        data: { me } = {},
        loading: meLoading,
    // error: meError,
    } = useFetch('/auth')

    const [company, setCompany] = useState(null)
    const {
        data: { data: { compData } = {} } = {},
        loading: compLoading,
    // error: compError,
    } = useFetch('/companies/' + company)
    const isLoading = meLoading || compLoading

    if (isLoading) {
        return (
            'Loading...'
        )
    }

    if (!me) {
        return (
            <Auth />
        )
    }

    const mustSelectCompany = !company && !me.permissions.admin
    console.log(me.permissions)
    return (
        <Main.Provider
            value={{
                me,
                company,
                setCompany,
                compData
            }}>
            <div className="App">
                <Router>
                    {mustSelectCompany && (
                        <>
                            <Header nothing /
                            ><SelectCompany />
                        </>
                    )}
                    {!mustSelectCompany && (
                        <>
                            <Header />
                            <Switch>
                                <Route exact path="/">
                                    <h1>Hello, {me.firstname}!</h1>
                                </Route>
                                {me.permissions.qr && <Route exact path="/qr/:item?">
                                    <QRPage />
                                </Route>}
                                {me.permissions.warehouse && <Route exact path="/warehouse/:item?/:id?">
                                    <Warehouse />
                                </Route>}
                                {me.permissions.warehouse &&
                                    <Route exact path="/cars">
                                        <Cars />
                                    </Route>
                                }
                                {me.permissions.sales && <Route exact path="/sales/:item?/:id?">
                                    SALES
                                </Route>}
                                {me.permissions.admin && <Route exact path="/admin">
                                    <Admin />
                                </Route>}
                            </Switch>
                        </>
                    )}
                </Router>
            </div>
        </Main.Provider>
    )
}

export default App
