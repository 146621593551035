import styles from './Table.module.sass'
import React, { useEffect, useState, useRef } from 'react'
import { useTable, useSortBy, usePagination, useColumnOrder } from 'react-table'
import Pagination from '@mui/material/Pagination'
import update from 'immutability-helper'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const Table = ({
    columns,
    data,
    white,
    handlePageChange,
    hide = [],
    columnOrder = [],
    handleDragChange,
    beige,
    perPage,
    setPerPage
}) => {
    const [records, setRecords] = useState(data.arr)
    useEffect(() => setRecords(data.arr), [data])
    const getRowId = row => {
        return row.id || row._id
    }
    const {
        getTableProps,
        headerGroups,
        getTableBodyProps,
        rows,
        prepareRow,
        state: { pageIndex },
        pageCount
    } = useTable({
        columns,
        data: records,
        getRowId,
        initialState: { pageIndex: Number(data.pageIndex), pageSize: 20, hiddenColumns: hide, columnOrder: columnOrder },
        manualPagination: true,
        pageCount: data.totalPages,
        sortTypes: {
            alphanumeric: (row1, row2, columnName) => {
                const rowOneColumn = row1.values[columnName];
                const rowTwoColumn = row2.values[columnName];
                if (isNaN(rowOneColumn)) {
                    return rowOneColumn?.toUpperCase() >
                        rowTwoColumn?.toUpperCase()
                        ? 1
                        : -1;
                }
                return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
            }
        }
    }, useSortBy, usePagination, useColumnOrder)


    
    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = records[dragIndex]
        setRecords(prev =>
            update(prev, {$splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRecord],
            ]})
        )
        handleDragChange(update(records, {$splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
        ]}))
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={`${styles.tableWrapper} ${white ? styles.white : ''} ${beige ? styles.beige : ''}`}>
                <table {...getTableProps()} className={`${styles.table}`} border="0" cellSpacing="0" cellPadding="0">
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <div className={`${styles.header} ${!column.disableSortBy ? styles.active : ''}`}>
                                            {column.render('Header')}
                                            {column.disableSortBy ? null :
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <img style={{transform: 'rotate(180deg)'}} src='/img/arrowUp.svg' alt='arrow up' />
                                                        : <img src='/img/arrowUp.svg' alt='arrow up' />
                                                    : <img src='/img/arrowDown.svg' alt='arrow down' />}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(
                            (row, index) =>
                                prepareRow(row) || (
                                    <Row
                                        index={index}
                                        row={row}
                                        moveRow={moveRow}
                                        handleDragChange={handleDragChange}
                                        {...row.getRowProps()}
                                    />
                                )
                        )}
                    </tbody>
                </table>
                {data.pageIndex &&
                <div className={styles.pagination}>
                    <div className={styles.dummy} />
                    <Pagination count={pageCount} page={pageIndex} onChange={(event, value) => {
                        handlePageChange(Number(value))
                    }} shape="rounded" color="primary" siblingCount={1} boundaryCount={1} sx={{
                        '& button.Mui-selected':{ background: '#7480AA', '&:hover': { background: '#7480AA' }}, 'button': { '&:hover': { background: '#eee' } }
                    }} />
                    <div className={styles.perPage}>
                        {perPage && <select value={perPage} onChange={e => setPerPage(e.target.value)}>
                            <option value='200'>200</option>
                            <option value='400'>400</option>
                            <option value='600'>600</option>
                            <option value='800'>800</option>
                            <option value='1000'>1000</option>
                        </select>}
                    </div>
                </div>}
            </div>
        </DndProvider>
    )
}



const Row = ({ row, index, moveRow }) => {
    const dropRef = useRef(null)
    const dragRef = useRef(null)

    const [, drop] = useDrop({
        accept: "CARD",
        hover(item, monitor) {
            if (!dropRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: "CARD",
        item: { type: "CARD", index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    const cursor = isDragging ? 'grabbing' : 'grab'

    preview(drop(dropRef))
    drag(dragRef)

    return (
        <tr ref={dropRef} style={{ opacity }}>
            {row.cells.map(cell => {
                return cell.column.id === 'settingsDND' ? <td {...cell.getCellProps()} ref={dragRef} style={{ cursor }}>{cell.render('Cell')}</td>
                    : <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
        </tr>
    )
}

export default Table