import axios from 'axios'
import { loadToken } from '../utils/storage'

export const useAxios = () => {
    axios.interceptors.response.use((response) => response, (error) => {
        if(error.response && error.response.status === 401)
            return Promise.reject(error)
    })
    axios.defaults.baseURL = process.env.REACT_APP_BACK_URL
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.headers.common['Authorization'] = loadToken()

    return axios
}

export default useAxios
