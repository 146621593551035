import styles from '../styles/SelectCompany.module.sass'
import React, { useState, useEffect, useContext } from 'react'
import { Palette } from 'react-palette'
import useAxios from '../hooks/useAxios'
import { Main } from '../App'

const SelectCompany = () => {
    const axios = useAxios()
    const [arr, setArr] = useState([])
    const [, setIsLoading] = useState(true)
    const getData = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get('/companies')
            setArr(res.data.data.arr)
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { setCompany } = useContext(Main)

    return (
        <>
            <p style={{
                width: '100%',
                marginTop: 50,
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '27px',
                textAlign: 'center',
                color: '#909090'
            }}>Select a company</p>
            <div className={styles.cardsGrid}>
                {arr.map(item =>
                    <Palette key={item._id} src={item.logo ? `${process.env.REACT_APP_BACK_URL}/static/${item.logo}` : '/img/nologo.jpg'}>
                        {({ data }) => (
                            <div onClick={() => setCompany(item._id)} style={{ '--color': data.vibrant, backgroundImage: item.logo ? `url(${process.env.REACT_APP_BACK_URL}/static/${item.logo})` : 'url(/img/nologo.jpg)' }} data-color={data.vibrant} className={`${styles.card} ${!item.ebayConnection ? styles.inactive : ''}`}>
                                <div className={styles.content}>
                                    <p>{item.title}</p>
                                    <span>{item.location}</span>
                                </div>
                            </div>
                        )}
                    </Palette>
                )}
            </div>
        </>
    )
}

export default SelectCompany
