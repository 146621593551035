import styles from '../styles/Auth.module.sass'
import React, { useState } from 'react'
import { Input, Button } from '../components'
import defaultAvatar from '../assets/avatar.svg'
import useAuth from '../hooks/useAuth'

const Auth = () => {
    const { login } = useAuth()
    const [data, setData] = useState({
        username: '', 
        password: ''
    })
    const [error, setError] = useState({
        username: null,
        password: null
    })

    const handleChange = e => setData(prev => ({...prev, [e.target.name]: e.target.value}))

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            const res = await login(data)
            if(res.message === 'Пользователь не найден') setError(prev => ({...prev, username: res.message}))
            if(res.message === 'Неверный логин или пароль') setError(prev => ({...prev, password: res.message}))
        } catch(err) {
            console.log(err)
        }
        console.log(e)
    }

    return(
        <div className={styles.card}>
            <img src={defaultAvatar} alt='avatar' />
            <form onSubmit={handleSubmit}>
                <Input value={data.username} onChange={handleChange} error={error.username} name='username' placeholder='Username' />
                <Input value={data.password} type='password' onChange={handleChange} error={error.password} name='password' placeholder='Password' />
                <Button type='submit'>Log In</Button>
            </form>
        </div>
    )
}

export default Auth
