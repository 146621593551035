import styles from './Header.module.sass'
import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Tabs, Tab } from '@mui/material'
import defaultAvatar from '../../assets/avatar.svg'
import { Main } from '../../App'
import {useHistory, Link} from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const useStyles = makeStyles({
    root: {
        height: '100%',
        '& > div > div': {
            height: '100%',
            '& > .Mui-selected': {
                background: '#EBFAFF'
            },
            '& span': {
                fontWeight: '600',
                color: '#2362C1'
            },
            '& > .MuiTabs-indicator': {
                background: '#5E9EFF'
            }
        }
    },
})

const Header = ({
    // children,
    nothing
}) => {
    const { logout } = useAuth()
    const history = useHistory()
    const { me, compData, setCompany } = useContext(Main)
    const classes = useStyles()
    const [value, setValue] = useState(`/${history.location.pathname.split('/')[1]}`)
    const handleChange = (event, newValue) => {
        setValue(newValue)
        console.log(newValue)
        history.push(newValue)
    }
    // useEffect(() => console.log(history), [history])

    return(
        <div className={styles.header}>
            {!nothing && <><div className={styles.me}>
                {!me.permissions.admin && <div className={styles.logo} onClick={() => setCompany(null)}>
                    <img src={`${process.env.REACT_APP_BACK_URL}/static/${compData?.logo}`} alt='logo' />
                </div>}
            </div>
            <Tabs
                className={classes.root}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                {me.permissions.qr && <Tab value='/qr' label="QR - CODE" onClick={() => history.push('/qr')} />}
                {me.permissions.warehouse && <Tab value='/cars' label="CARS" onClick={() => history.push('/cars')} />}
                {me.permissions.warehouse && <Tab value='/warehouse' label="WAREHOUSE" onClick={() => history.push('/warehouse')} />}
                {me.permissions.sales && <Tab value='/sales' label="SALES" onClick={() => history.push('/sales')} />}
                {me.permissions.admin && <Tab value='/admin' label="ADMIN PANEL" onClick={() => history.push('/admin')} />}
            </Tabs></>}
            <div className={styles.me} style={nothing ? {marginLeft: 'auto'} : {}}>
                {me.username}
                <div className={styles.logo}>
                    <img src={me.avatar ? `${process.env.REACT_APP_BACK_URL}/static/${me.avatar}` : defaultAvatar} alt='logo' />
                </div>
                <div className={styles.dropDown}>
                    <div onClick={logout}>Log out</div>
                </div>
            </div>
            {!nothing && value === '/qr' && <div className={styles.down}>
                <Link to='/qr/car' className={`${styles.menuBtn} ${history.location.pathname === '/qr/car' ? styles.active : ''}`}>
                Car code
                </Link>
                <div className={styles.separator} />
                <Link to='/qr/warehouse' className={`${styles.menuBtn} ${history.location.pathname === '/qr/warehouse' ? styles.active : ''}`}>
                Warehouse code
                </Link>
            </div>}
        </div>
    )
}
export default Header
