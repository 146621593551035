import TextField from '@mui/material/TextField'
import styles from './Input.module.sass'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'
import React from 'react'

const Input = ({
    value = null,
    onChange = () => {},
    error = null,
    type = 'text',
    placeholder = null,
    autoFocus,
    name,
    children,
    onFocus
}) => {
    if(type === 'checkbox') {
        return <FormControlLabel fullWidth label={placeholder} control={ 
            <Checkbox checked={value} onChange={onChange} name={name}
                inputProps={{ 'aria-label': 'controlled' }} />
        }/>
    }

    if(type === 'select') {
        return (
            <FormControl name={name} fullWidth size='small'>
                <InputLabel name={name} error={error !== null} id="demo-simple-select-label">{placeholder}</InputLabel>
                <Select
                    onFocus={onFocus}
                    className={styles.input}
                    labelId="demo-simple-select-label"
                    value={value}
                    label={placeholder}
                    onChange={onChange}
                    name={name}
                    error={error !== null}
                    // helperText={error}
                    size='small'
                >
                    {children}
                </Select>
            </FormControl>
        )
    }

    return (
        <TextField fullWidth onFocus={onFocus} className={styles.input}
            autoFocus={autoFocus}
            name={name} variant='outlined' size='small'
            label={placeholder} type={type} value={value}
            onChange={onChange} error={error !== null} helperText={error}
        />
    )
}

export default Input